import React, { FC } from "react";
import styles from "./link.module.scss";
import NextLink from "next/link";

type LinkProps = {
	href: string;
	className?: string;
};

const Link: FC<LinkProps> = ({ href, children, className }) => (
	<NextLink href={href}>
		<a className={className ? `${styles.link} ${className}` : `${styles.link}`}>
			{children}
		</a>
	</NextLink>
);

export default Link;
