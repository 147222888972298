import { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../lib/apollo";
import { Toaster } from "react-hot-toast";
import ProgressBar from "nextjs-progressbar";
import { Pages } from "../lib/pages";
import Layout from "../components/layout";
import "../styles/global.scss";
import { useMemo } from "react";

import * as sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_URL,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
});

const App = ({ Component, pageProps, router }: AppProps) => {
	const apolloClient = useApollo(pageProps.initialApolloState);
	const title = useMemo(() => {
		switch (router.pathname) {
			case Pages.Index:
				return null;
			case Pages.About:
				return "O projektu";
			case Pages.Login:
				return "Přihlášení";
			case Pages.Logout:
				return "Odhlášování";
			case Pages.PrivacyPolicy:
				return "Ochrana osobních údajů";
			case Pages.Profile:
				return "Profil";
			case Pages.Register:
				return "Registrace";
			case Pages.TermAndConditions:
				return "Podmínky používání";
			case Pages.ResetPassword:
				return "Resetování hesla";
			case Pages.Cards:
				return "Seznam kartiček";
			default:
				return null;
		}
	}, [router.pathname]);

	if (router.pathname === Pages.Game)
		return (
			<ApolloProvider client={apolloClient}>
				<Component {...pageProps} />
			</ApolloProvider>
		);

	return (
		<ApolloProvider client={apolloClient}>
			<ProgressBar
				color="#ffffff"
				height={2}
				options={{
					showSpinner: false,
				}}
			/>
			<Layout title={title}>
				<Component {...pageProps} />
			</Layout>
			<Toaster />
		</ApolloProvider>
	);
};

export default App;
