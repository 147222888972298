export enum Pages {
	Index = "/",
	About = "/o-projektu",
	Game = "/hra",
	Login = "/prihlaseni",
	Logout = "/odhlaseni",
	PrivacyPolicy = "/ochrana-osobnich-udaju",
	Profile = "/profil",
	Register = "/registrace",
	TermAndConditions = "/podminky-pouzivani",
	ResetPassword = "/resetovat-heslo",
	SendEmail = "/postlat-email",
	Cards = "/karticky",
}
