import React, { FC } from "react";
import { Link } from "..";
import styles from "./link.module.scss";
import { AiFillPlusCircle } from "react-icons/ai";

type LinkProps = {
	href: string;
};

const WhiteLink: FC<LinkProps> = ({ children, href }) => (
	<Link href={href}>
		<div className={styles.white}>
			<AiFillPlusCircle className={styles.icon} />
			{children}
		</div>
	</Link>
);

export default WhiteLink;
