import React, { FC, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { GetAuth } from "../../lib/auth";
import type { JwtPayload } from "../../types/jwt";
import styles from "./username-login.module.scss";
import { Link } from "../basic";
import { Pages } from "../../lib/pages";

const getUserName = (): string | null => {
	const rawToken = GetAuth();

	if (!rawToken) return null;

	const token = rawToken.split(" ")[1];
	if (!token || token === "") return null;

	try {
		return jwt_decode<JwtPayload>(token).userName;
	} catch {
		return null;
	}
};

const UsernameLogin: FC = () => {
	const [userName, setUserName] = useState("");
	useEffect(() => {
		setUserName(getUserName());
	}, []);

	if (userName)
		return (
			<div className={styles.flex}>
				<Link href={Pages.Logout} className={styles.link}>
					Odhlásit se
				</Link>
				<Link href={Pages.Profile} className={styles.link}>
					{userName}
				</Link>
			</div>
		);

	return (
		<div className={styles.flex}>
			<Link href={Pages.Login} className={styles.link}>
				Přihlásit se
			</Link>
			<Link href={Pages.Register} className={styles.link}>
				Zaregistrovat se
			</Link>
		</div>
	);
};

export default UsernameLogin;
