import React, { FC } from "react";
import { Logo } from "../../images";
import styles from "./center.module.scss";

const Center: FC = ({ children }) => (
	<div className={styles.center}>
		<div>
			<Logo />
			{children}
		</div>
	</div>
);

export default Center;
