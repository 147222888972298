import React, { FC } from "react";
import Head from "next/head";
import { Navbar } from "../navbar";
import { Background } from "../background";

type LayoutProps = {
	title?: string;
};

export const Layout: FC<LayoutProps> = ({ children, title }) => {
	return (
		<>
			<Head>
				<title>{title ? `${title} - ` : ""} Codeventure</title>
			</Head>
			<main>
				<Navbar />
				<Background>{children}</Background>
			</main>
		</>
	);
};
