import React, { FC } from "react";
import Image from "next/image";
import customImageLoader from "../../../lib/imageLoader";

const Logo: FC = () => (
	<Image
		src="codeventure-logo"
		layout="responsive"
		width={960}
		height={211}
		sizes="25vw"
		alt="Codeventure Logo"
		priority
		loader={customImageLoader}
	/>
);

export default Logo;
