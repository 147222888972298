import React, { FC } from "react";
import { Container, White } from "..";

const ContainerWhite: FC = ({ children }) => (
	<Container>
		<White>{children}</White>
	</Container>
);

export default ContainerWhite;
