import React, { FC } from "react";
import { FlexCenter } from "..";
import styles from "./white-box.module.scss";

type WhiteBoxProps = {
	margin?: number;
};

const WhiteBox: FC<WhiteBoxProps> = ({ children, margin }) => (
	<FlexCenter>
		<div className={styles.whitebox} style={{ margin: `${margin ?? 0}px 0` }}>
			<FlexCenter>{children}</FlexCenter>
		</div>
	</FlexCenter>
);

export default WhiteBox;
